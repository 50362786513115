<template>
  <TTView>
    <VRow>
      <VCol>
        TTTextfield
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <div class="tt-text-title-1">
          Primary
        </div>
        <div class="tt-text-subtitle">
          Full
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          56px
        </div>
        <TTTextField
          x-large
          placeholder="Placeholder"
          label="Title"
          :messages="['Description']"
        >
          <template #append-icon>
            <VIcon size="16">
              fal fa-search
            </VIcon>
          </template>
        </TTTextField>
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          44px
        </div>
        <TTTextField
          v-model="field"
          large
          :messages="['Description']"
          placeholder="Placeholder"
          label="Title"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          36px
        </div>
        <TTTextField
          :messages="['Description']"
          placeholder="Placeholder"
          label="Title"
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          28px
        </div>
        <TTTextField
          small
          :messages="['Description']"
          placeholder="Placeholder"
          label="Title"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <div class="tt-text-subtitle my-4">
          Without title
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          56px
        </div>
        <TTTextField
          x-large
          :messages="['Description']"
          placeholder="Placeholder"
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          44px
        </div>
        <TTTextField
          large
          :messages="['Description']"
          placeholder="Placeholder"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          36px
        </div>
        <TTTextField
          :messages="['Description']"
          placeholder="Placeholder"
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          28px
        </div>
        <TTTextField
          small
          :messages="['Description']"
          placeholder="Placeholder"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <div class="tt-text-subtitle my-10">
          Fields only
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          56px
        </div>
        <TTTextField
          x-large
          placeholder="Placeholder"
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          44px
        </div>
        <TTTextField
          placeholder="Placeholder"
          large
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          36px
        </div>
        <TTTextField
          placeholder="Placeholder"
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          28px
        </div>
        <TTTextField
          small
          placeholder="Placeholder"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <div class="tt-text-subtitle my-10">
          Unactive
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          56px
        </div>
        <TTTextField
          x-large
          placeholder="Placeholder"
          disabled
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          44px
        </div>
        <TTTextField
          placeholder="Placeholder"
          large
          disabled
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          36px
        </div>
        <TTTextField
          placeholder="Placeholder"
          disabled
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          28px
        </div>
        <TTTextField
          small
          placeholder="Placeholder"
          disabled
          value="123"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <div class="tt-text-subtitle my-10">
          With icon
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          56px
        </div>
        <TTTextField
          x-large
          placeholder="Placeholder"
          :messages="['Description']"
          label="Title"
          append-icon="fal fa-search"
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          44px
        </div>
        <TTTextField
          large
          placeholder="Placeholder"
          :messages="['Description']"
          label="Title"
          append-icon="fal fa-search"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          36px
        </div>
        <TTTextField
          placeholder="Placeholder"
          append-icon="fal fa-search"
          :messages="['Description']"
          label="Title"
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          28px
        </div>
        <TTTextField
          small
          append-icon="fal fa-search"
          placeholder="Placeholder"
          :messages="['Description']"
          label="Title"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <div class="tt-text-subtitle my-10">
          Outlined
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          44px
        </div>
        <TTTextField
          large
          placeholder="Placeholder"
          outlined
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          36px
        </div>
        <TTTextField
          placeholder="Placeholder"
          outlined
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          28px
        </div>
        <TTTextField
          small
          placeholder="Placeholder"
          outlined
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <div class="tt-text-subtitle my-10">
          Outlined with icons
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          44px
        </div>
        <TTTextField
          large
          placeholder="Placeholder"
          append-icon="fal fa-search"
          outlined
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          36px
        </div>
        <TTTextField
          placeholder="Placeholder"
          append-icon="fal fa-search"
          outlined
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          28px
        </div>
        <TTTextField
          small
          placeholder="Placeholder"
          append-icon="fal fa-search"
          outlined
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <h2 class="tt-text-subtitle mt-10">
          Title from slot
        </h2>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="4">
        <TTTextField
          large
          placeholder="Placeholder"
          append-icon="fal fa-search"
          outlined
        >
          <template #label>
            Label from slot
          </template>
        </TTTextField>
      </VCol>
      <VCol cols="4">
        <TTTextField
          placeholder="Placeholder"
          append-icon="fal fa-search"
          outlined
        >
          <template #label>
            Label with icon
            <VIcon
              color="success"
              small
            >
              fa fa-check-circle
            </VIcon>
          </template>
        </TTTextField>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'Textfield',
  data() {
    return {
      form: true,
      field: '',
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      val: 'Я настоящий текст',
      disabled: false,
      error: true,
      errorMessages: ['Error description'],
      rules: {
        required: (value) => (value && value.trim().length > 0) || 'Обязательное поле',
        minLength: (min) => (value) => (value && value.trim().length >= min) || `Минимальная длина ${min} симв.`,
      },
    };
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    validate() {
      this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    log(...args) {
      console.warn(args);
    },
  },
};
</script>

<style lang="scss">
</style>
